import PeopleSection from "./peoplesection";
import BookSection from "./booksection";
import Quote from "./quote";
import AffiliateButton from "./affiliatebutton";
import { get_random, split_sequence, normalize_graphql_data } from "./utils";
import { Link } from "gatsby";
import categories from "../categories";
import SEO from "./seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
function BookDetail({ books, other_books, other_people }) {
  // let { id } = useParams();
  let book = normalize_graphql_data(books)[0];
  console.log(book);

  let rec_people = book["people"];

  // rec_people.sort((a, b) => a["person_ranking"] - b["person_ranking"]);
  // let random_books = get_random(books, 12, "book_code");

  return (
    <div className="gap-y-5 lg:px-24 px-5">
      <SEO
        title={"Livros parecidos com " + book.name}
        imagesharp={book.image}
        description={
          "Interessado no livro " +
          book.name +
          "? | Descubra livros parecidos recomendados pelas pessoas mais bem sucedidas do mundo."
        }
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 mb-8 lg:mb-16">
        <div className="grid place-items-start justify-self-center ">
          <div className="grid sticky top-0 py-5 place-items-center">
            <GatsbyImage image={getImage(book.image)} alt={book.name} />
            {/* <img
              className="shadow-lg max-w-[50%] lg:max-w-xs"
              src={book.image}
              alt={book.name}
            /> */}
            <div className="py-10">
              <AffiliateButton affiliate_link={book.amazon_affiliate} />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 lg:px-24">
          <div>
            <h1 className="text-blue-900 text-xl lg:text-4xl font-light">
              {book.name}
            </h1>
            <p className="text-blue-900 text-lg font-light">
              por {book.author}
            </p>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-4 text-indigo-800 font-light text-xl">
              <div className="prose prose-slate">
                <MDXRenderer>{book.full_description[0].body}</MDXRenderer>
              </div>

              {/* <div
                className="prose lg:prose-xl prose-slate"
                dangerouslySetInnerHTML={{ __html: book.book_description }}
              ></div> */}
            </div>
            <div>
              <AffiliateButton affiliate_link={book.amazon_affiliate} />
            </div>
            <div className="flex flex-row flex-wrap gap-2">
              {split_sequence(book["book_attributes"]).map((att) => (
                <Link to={"/categoria/" + categories["url_books"][att]}>
                  <div className="px-2 py-1 bg-blue-100 rounded-sm text-xs text-blue-800 font-light hover:bg-blue-200">
                    {att}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <PeopleSection
        people={rec_people}
        cta={rec_people.length + " recomendações para " + book.name}
      />
      <BookSection books={other_books} cta="Recomendações similares" />
      <Quote />
    </div>
  );
}

export default BookDetail;
