import { graphql } from "gatsby";
import BookDetail from "../../components/bookdetail";
import Layout from "../../components/layout";
import { normalize_graphql_data } from "../../components/utils";

const BookDetailPage = ({ data, pageContext }) => {
  let target_books = normalize_graphql_data(data.target.edges);
  let other_books = normalize_graphql_data(data.other_books.edges);
  let other_people = normalize_graphql_data(data.other_people.edges);

  console.log(target_books);

  return (
    <Layout>
      <BookDetail
        books={target_books}
        other_books={other_books}
        other_people={other_people}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($book_code: String) {
    target: allBeeksJson(
      limit: 12
      sort: { fields: recommended_volume, order: DESC }
      filter: { book_code: { eq: $book_code } }
    ) {
      edges {
        node {
          id
          amazon_affiliate
          author
          book_attributes
          book_code
          image {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
            }
          }
          name
          full_description {
            body
          }
          book_description
          recommended_volume
          people {
            person_code
            person_image {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: BLURRED
                )
              }
            }
            person_name
            person_ranking

            person_attributes
          }
        }
      }
    }
    other_people: allPeepsJson(limit: 12, sort: { fields: person_ranking }) {
      edges {
        node {
          id
          person_attributes
          person_code
          person_image {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
            }
          }
          person_name
        }
      }
    }
    other_books: allBeeksJson(
      limit: 12
      skip: 48
      sort: { fields: recommended_volume, order: DESC }
    ) {
      edges {
        node {
          id
          amazon_affiliate
          author
          book_code
          image {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
            }
          }
          name
        }
      }
    }
  }
`;

export default BookDetailPage;
